import "./Footer.css";
import OwnerData from "../../Data/OwnerData";
import { Link } from "react-router-dom";
import {
  FaDiscord,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaSquareBehance,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
const Footer = () => {
  // eslint-disable-next-line
  const links = OwnerData.links.map((hehe, i) => {
    if (hehe.app === "isInsta") {
      return (
        <Link target="_blank" to={hehe.url + hehe.username} key={i}>
          <FaInstagram />
          {/* {hehe.aName} */}
        </Link>
      );
    } else if (hehe.app === "isX") {
      return (
        <Link target="_blank" to={hehe.url + hehe.username} key={i}>
          <FaXTwitter />
          {/* {hehe.aName} */}
        </Link>
      );
    } else if (hehe.app === "isBe") {
      return (
        <Link target="_blank" to={hehe.url + hehe.username} key={i}>
          <FaSquareBehance />
          {/* {hehe.aName} */}
        </Link>
      );
    } else if (hehe.app === "isYt") {
      return (
        <Link target="_blank" to={hehe.url + hehe.username} key={i}>
          <FaYoutube />
          {/* {hehe.aName} */}
        </Link>
      );
    } else if (hehe.app === "isFb") {
      return (
        <Link target="_blank" to={hehe.url + hehe.username} key={i}>
          <FaFacebook />
          {/* {hehe.aName} */}
        </Link>
      );
    } else if (hehe.app === "isDiscord") {
      return (
        <Link target="_blank" to={hehe.url} key={i}>
          <FaDiscord />
        </Link>
      );
    } else if (hehe.app === "isMail") {
      return (
        <Link target="_blank" to={hehe.url} key={i}>
          <FaEnvelope />
        </Link>
      );
    }
  });
  return (
    <div className="footer">
      <span>{OwnerData.name + " 2020 - " + OwnerData.year}</span>
      <span className="links">{links}</span>
    </div>
  );
};

export default Footer;
