import "./Pannel.css";
const Pannel = (props) => {
  return (
    <div className="pannel" key={props.key}>
      {props.children}
    </div>
  );
};

export default Pannel;
