import "./App.css";
import { Container, Footer, NavBar } from "./components";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  NotFound,
  Home,
  About,
  Skills,
  Projects,
  FeedBacks,
  GetStart,
} from "./pages";
import { AnimatePresence } from "framer-motion";

function App() {
  return (
    <>
      <AnimatePresence>
        <Router>
          <NavBar />
          <Container>
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/skills" element={<Skills />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/feedbacks" element={<FeedBacks />} />
              <Route path="/getstart" element={<GetStart />} />
            </Routes>
          </Container>
          <Footer />
        </Router>
      </AnimatePresence>
    </>
  );
}

export default App;
