const FeedBacksData = [
    {
        date: "27/05/2024",
        name: "Ch.R",
        feed: "Esso, et c'est tout.",
        img: "https://imgur.com/erd5qs3.png",
    },
    {
        date: "09/04/2024",
        name: "Amine",
        feed: "Esslam really impressed me with his experience in designing and programming. I truly recommend him.",
        img: "https://imgur.com/meL25li.png",
    },
    {
        date: "09/09/2024",
        name: "Jana",
        feed: "Esslam has big ideas and suggestions to improve brands. I love his creative thinking and the quality of his work.",
        img: "https://imgur.com/BK10JrS.png",
    },
];
export default FeedBacksData;
