const NavItemData = [
  { id: "0", content: "Home", href: "/", isMain: false, dropdown: false },
  {
    id: "1",
    content: "My",
    href: "#",
    dropdown: true,
    isMain: false,
    items: [
      { id: "2", content: "About", href: "/about" },
      { id: "3", content: "Skills", href: "/skills" },
      { id: "4", content: "Projects", href: "/projects" },
    ],
  },
  {
    id: "5",
    content: "FeedBacks",
    href: "/feedbacks",
    isMain: false,
    dropdown: false,
  },
  {
    id: "6",
    content: "GetStart",
    href: "/getstart",
    isMain: true,
    dropdown: false,
  },
];
export default NavItemData;
