import "./NotFound.css";
import { Fa0, Fa4 } from "react-icons/fa6";
import { Button, HeadLine } from "../../components";

const NotFound = () => {
  return (
    <>
      <div className="notFound">
        <HeadLine>
          <span className="glitch paths">
            {/* <FaFaceDizzy /> */}
            <Fa4 />
            <Fa0 />
            <Fa4 />
          </span>
        </HeadLine>
        <Button content="Back To Home" linkTo="/" />
      </div>
    </>
  );
};

export default NotFound;
