const ProjectData = [
    {
        name: "Drrajez | Brand Identity",
        url: "https://www.behance.net/gallery/198175165/Drrajez-Brand-Identity",
        published_on: 1715254182,
        covers: {
            "808": "https://imgur.com/ev2olw7.png",
        },
    }, {
        name: "Random Discord Designs",
        url: "https://www.behance.net/gallery/209332117/Random-Discord-Designs",
        published_on: 1727897834,
        covers: {
            "808": "https://imgur.com/DOFU8f3.png",
        },
    }, {
        name: "Instagram Posts | IPhone look like",
        url: "https://www.behance.net/gallery/209334231/Instagram-Posts-IPhone-look-like",
        published_on: 1727898802,
        covers:
        {
            "808": "https://imgur.com/qZSKWYh.png",
        },
    },
];
export default ProjectData;
