import "./Skills.css";
import SkillsData from "../../Data/SkillsData";
import {
  Button,
  Pannel,
  PageContainer,
  IllustratorContainer,
} from "../../components";
import { FaBrain, FaCode, FaPaintbrush } from "react-icons/fa6";
const Skills = () => {
  // eslint-disable-next-line
  const skills = SkillsData.map((skill) => {
    const isBackEnd = skill.isBackEnd;
    if (isBackEnd === true) {
      return (
        <>
          <Pannel key={skill.id} id="isBack">
            <span className="name" key={skill.id}>
              <FaCode />
              {skill.name}
              <span>BackEnd</span>
            </span>
            <span className="desc" key={skill.id}>
              {skill.desc}
            </span>
          </Pannel>
        </>
      );
    } else if (isBackEnd === false) {
      return (
        <>
          <Pannel key={skill.id} id="isFront">
            <span className="name" key={skill.id}>
              <FaPaintbrush />
              {skill.name}
              <span>FrontEnd</span>
            </span>
            <span className="desc" key={skill.id}>
              {skill.desc}
            </span>
          </Pannel>
        </>
      );
    }
  });
  return (
    <PageContainer>
      <div className="skillsWrapper">
        <div className="skillsContainer">{skills}</div>
        <Button content="Some Projects" linkTo="/projects" />
      </div>
      <IllustratorContainer>
        <FaBrain />
      </IllustratorContainer>
    </PageContainer>
  );
};

export default Skills;
