import "./ProjectCard.css";
import { Pannel } from "..";
import { Link } from "react-router-dom";

const ProjectCard = ({ project }) => {
  const { name, url, covers, published_on } = project;
  const date = new Date(published_on * 1000);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const publishedDate = `${day}/${month}/${year}`;
  return (
    <>
      <Pannel>
        <div className="card-header">
          <img src={covers["808"]} alt={name} />
        </div>
        <div className="card-info">
          <Link to={url} target="_blank" className="name">
            {name}
          </Link>
          <span className="desc">{publishedDate}</span>
        </div>
      </Pannel>
    </>
  );
};

export default ProjectCard;
