import { FaFeatherPointed } from "react-icons/fa6";
import "./GetStart.css";
import {
  PageContainer,
  IllustratorContainer,
  Pannel,
  GetStartedButton,
  Success,
  Faild,
} from "../../components";

import { useState } from "react";
const GetStart = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(
      "https://discord.com/api/webhooks/1251868165911220274/p2P618LoXkVYCiVAslhBW9Zxv6uKk8C50hI3pcnu5121uQVjjIZQmLDEb4-iA7qqXIoK",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content: `**${name}** (*${email}*): 
${message}`,
        }),
      }
    )
      .then(() => {
        setStatus("success");
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        console.log(error);
        setStatus("error");
      });
  };

  return (
    <PageContainer>
      <div className="getstartWraper">
        <Pannel>
          {status === "success" ? (
            <Success msg="Your message sent successfully!" />
          ) : status === "error" ? (
            <Faild msg="We have some problems" />
          ) : null}
          <div className="otherIdk">
            <div className="inputContainer">
              <span>Full Name</span>
              <input
                type="name"
                required="true"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="inputContainer">
              <span>Email</span>
              <input
                type="email"
                name="email"
                required="true"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="inputContainer">
              <span>Message</span>
              <textarea
                name="message"
                maxLength={1760}
                cols="70"
                rows="10"
                value={message}
                required="true"
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
          </div>
          <GetStartedButton
            id="getStartedForm"
            content="SEND"
            onClick={handleSubmit}
          />
        </Pannel>
      </div>
      <IllustratorContainer>
        <FaFeatherPointed />
      </IllustratorContainer>
    </PageContainer>
  );
};

export default GetStart;
