import "./Home.css";
import { FaFaceSmileWink, FaHand } from "react-icons/fa6";
import {
  Button,
  HeadLine,
  IllustratorContainer,
  PageContainer,
  Typing,
} from "../../components";
import { motion } from "framer-motion";

const Home = () => {
  return (
    <PageContainer>
      <div className="homeContent">
        <div className="textss">
          <span className="subTitle">Welcome There,</span>
          <HeadLine>
            <motion.span
              key="modal"
              initial={{ y: 50, opacity: "0%" }}
              animate={{ y: 0, opacity: "100%", transitionDuration: "150ms" }}
              exit={{ y: 50, opacity: "0%" }}
            >
              Let's shape a
            </motion.span>
            <motion.span
              key="modal"
              initial={{ y: 50, opacity: "0%" }}
              animate={{ y: 0, opacity: "100%", transitionDuration: "50ms" }}
              exit={{ y: 50, opacity: "0%" }}
              className="notGap"
            >
              unique future
            </motion.span>
            <motion.span
              key="modal"
              initial={{ y: 50, opacity: "0%" }}
              animate={{ y: 0, opacity: "100%", transitionDuration: "10ms" }}
              exit={{ y: 50, opacity: "0%" }}
              className="notGap underlined"
            >
              Together
            </motion.span>
          </HeadLine>
          <Typing />
        </div>
        <Button content="Know More" linkTo="/about" />
      </div>
      <IllustratorContainer>
        <FaFaceSmileWink />
        <div className="slam">
          <FaHand />
        </div>
      </IllustratorContainer>
    </PageContainer>
  );
};

export default Home;
