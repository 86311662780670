import "./NavBar.css";
import NavItem, { NavItemDropDown, NavItemDropped, Toggle } from "./NavItem";
import NavItemData from "../../Data/NavData";
import { LogoTypo } from "../../assets/Logos";
import { GetStartedButton } from "../Buttons/Button";
import { Link } from "react-router-dom";
import { useState } from "react";

const NavBar = () => {
  const [status, setStatus] = useState(null);

  const handelToggle = function () {
    if (status === "open") {
      setStatus("close");
    } else {
      setStatus("open");
    }
  };
  const navItems = NavItemData.map((navItem, i) => {
    if (navItem.dropdown === true) {
      const childrens = navItem.items.map((e, i) => (
        <NavItemDropped key={i} href={e.href} content={e.content} />
      ));
      return (
        <NavItemDropDown key={i} href={navItem.href} content={navItem.content}>
          {childrens}
        </NavItemDropDown>
      );
    } else if (navItem.isMain === true) {
      return (
        <li key={i}>
          <GetStartedButton
            key={i}
            content={navItem.content}
            linkTo={navItem.href}
          />
        </li>
      );
    } else {
      return (
        <NavItem
          key={i}
          href={navItem.href}
          content={navItem.content}
          mainClass="nothing"
        />
      );
    }
  });
  return (
    <>
      <div className="navBar">
        <Link to="./">
          <LogoTypo />
        </Link>
        <ul className="navItems">{navItems}</ul>
        <Toggle onClick={handelToggle} />
      </div>

      {status === "open" ? (
        <div className="mobileNavItems">
          <ul>{navItems}</ul>
        </div>
      ) : status === "close" ? (
        " "
      ) : null}
    </>
  );
};

export default NavBar;
