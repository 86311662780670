import { Link } from "react-router-dom";
import "./NavItem.css";
import { FaMoon } from "react-icons/fa6";

const NavItem = (props) => {
  return (
    <li className={"navItem " + props.mainClass}>
      <Link to={props.href}>{props.content}</Link>
    </li>
  );
};
const NavItemDropDown = (props) => {
  return (
    <li className="navItem dropdown">
      <Link to={props.href}>{props.content}</Link>
      <ul className="droppedMenu">{props.children}</ul>
    </li>
  );
};

const NavItemDropped = (props) => {
  return (
    <li className="droppedItem">
      <Link to={props.href}>{props.content}</Link>
    </li>
  );
};
const Toggle = (props) => {
  return (
    <li className="toggle" onClick={props.onClick}>
      <Link to="#">
        <FaMoon />
      </Link>
    </li>
  );
};
export default NavItem;
export { NavItemDropDown, NavItemDropped, Toggle };
