import "./About.css";
import OwnerData from "../../Data/OwnerData";
import {
  Button,
  HeadLine,
  IllustratorContainer,
  PageContainer,
} from "../../components";
import LogoIcon from "../../assets/Logos";
import { motion } from "framer-motion";

const About = () => {
  return (
    <PageContainer>
      <div className="aboutContent">
        <div>
          <HeadLine>About Me</HeadLine>
          <motion.span
            key="modal"
            initial={{ y: 500, opacity: "0%" }}
            animate={{ y: 0, opacity: "100%", transitionDuration: "150ms" }}
            exit={{ y: -500, opacity: "0%" }}
            className="paragraph"
          >
            {OwnerData.ad + OwnerData.aboutme}
          </motion.span>
        </div>
        <Button content="What I Know?" linkTo="/skills" />
      </div>
      <IllustratorContainer>
        <LogoIcon className="bigLittleBit" />
      </IllustratorContainer>
    </PageContainer>
  );
};

export default About;
