import {
  PageContainer,
  FeedBackCard,
  GetStartedButton,
} from "../../components/";
import "./FeedBacks.css";
import FeedBacksData from "../../Data/FeedBacksData";
const feedbacks = FeedBacksData.map((feedback, i) => {
  return (
    <>
      <FeedBackCard
        key={i}
        name={feedback.name}
        img={feedback.img}
        date={feedback.date}
        feed={feedback.feed}
      />
    </>
  );
});
const FeedBacks = () => {
  return (
    <PageContainer>
      <div className="feedbacksWrapper">
        <div className="feedbacksContainer">{feedbacks}</div>
        <GetStartedButton content="GetStart" linkTo="/getstart" />
      </div>
    </PageContainer>
  );
};

export default FeedBacks;
