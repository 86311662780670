import "./FeedBackCard.css";
import { Pannel } from "..";
const FeedBackCard = (props) => {
  return (
    <>
      <Pannel key={props.key}>
        <div className="header">
          <img src={props.img} alt={props.name} className="imgContainer" />
          <span className="infoContainer">
            <span className="name">{props.name}</span>
            <span className="date">{props.date}</span>
          </span>
        </div>
        <div className="body">
          <span className="paragraph">{props.feed}</span>
        </div>
      </Pannel>
    </>
  );
};

export default FeedBackCard;
