import "./Messages.css";

const Success = (props) => {
  return <div className="success">{props.msg}</div>;
};
const Faild = (props) => {
  return <div className="faild">{props.msg}</div>;
};

export default Success;
export { Faild };
