const thisYear = new Date().getFullYear();
const thisIsAge = thisYear - 2008;
const thisIsName = "Esslam";
const thisIsRealName = "Islam";
const thisIsCountry = "Algeria";
const thisIsWilaya = "Oran";
const thisIsGraphicStart = thisYear - 2012;
const thisIsGraphicExperince = thisIsAge - thisIsGraphicStart + " years";
const thisIsDevStart = thisYear - 2013;
const thisIsDevExperince = thisIsAge - thisIsDevStart + " years";
const thisIsDesignTeacher = "Mohamed Ali Nour El-deen (Nour Design)";
const thisIsDevTeacher = "Nour Homsi";
const essrks = "essrks";
const OwnerData = {
  year: thisYear,
  name: thisIsName,
  realName: thisIsRealName,
  country: thisIsCountry,
  links: [
    {
      aName: "Instagram",
      app: "isInsta",
      url: "https://instagram.com/",
      username: essrks,
    },
    {
      aName: "Instagram",
      app: "isInsta",
      url: "https://instagram.com/",
      username: "ia1q_",
    },
    {
      aName: "X Corp",
      app: "isX",
      url: "https://x.com/",
      username: "ia1q_",
    },
    {
      aName: "Behance",
      app: "isBe",
      url: "https://be.net/",
      username: "znu",
    },
    {
      aName: "YouTube",
      app: "isYt",
      url: "https://youtube.com/@",
      username: essrks,
    },
    {
      aName: "FaceBook",
      app: "isFb",
      url: "https://fb.com/",
      username: essrks,
    },
    {
      aName: "Discord",
      app: "isDiscord",
      url: "https://discord.gg/znu",
    }, {
      aName: "Mail",
      app: "isMail",
      url: "mailto:me@exalter.cc",
    },
  ],
  age: thisIsAge,
  ad:
    "Dear Visitor, I am a Graphic Designer" +
    " who can upscaling your brand to better" +
    " because elevating your brand is my passion" +
    " Additionally, my web design services are superior to expensive plans." +
    " Check out my skills, my previous projects, and client feedback." +
    " Finally, contact me through GetStart.",
  aboutme:
    " Hey, my name is " +
    thisIsRealName +
    " (I prefer " +
    thisIsName +
    "), I am " +
    thisIsAge +
    " years old" +
    " from " +
    thisIsWilaya +
    ", " +
    thisIsCountry +

    ". I start learning as Graphic Designer at the age " +
    thisIsGraphicStart +
    ", under the mentorship of Mr." +
    thisIsDesignTeacher +
    ". Now, I have " +
    thisIsGraphicExperince +
    " of experience in this career" +
    "\n\n" +
    "Additionally, at the age of " +
    thisIsDevStart +
    ", I began learning FullStack Development from Mr." +
    thisIsDevTeacher +
    ", who guided me in the right direction. I currently have " +
    thisIsDevExperince +
    " of experience in developing projects and making significant updates in my life.",
};
export default OwnerData;
