import "./Projects.css";
import { Button, PageContainer, ProjectCard } from "../../components";
import ProjectData from "../../Data/ProjectData";

const Projects = () => {
  return (
    <PageContainer>
      <div className="projectsWrapper">
        <div className="projectsContainer">
          {ProjectData.map((project, i) => (
            <ProjectCard key={i} project={project} />
          ))}
        </div>
        <Button content="Clients Experiences [CX]" linkTo="/feedbacks" />
      </div>
    </PageContainer>
  );
};

export default Projects;
