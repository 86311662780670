import { Link } from "react-router-dom";
import "./Button.css";
import { FaArrowRight, FaStar } from "react-icons/fa6";
const NextButton = (props) => {
  return (
    <div className="btn">
      <Link to={props.linkTo}>
        {props.content}
        <FaArrowRight />
      </Link>
    </div>
  );
};
const GetStartedButton = (props) => {
  return (
    <div className="btn getStart" onClick={props.onClick}>
      <Link to={props.linkTo}>
        {props.content}
        <FaStar />
      </Link>
    </div>
  );
};
export default NextButton;
export { GetStartedButton };
