import "./PageContainer.css";
import { motion } from "framer-motion";

const PageContainer = (props) => {
  return (
    <motion.div
      className="pageContainer"
      initial={{ y: 100, width: 0, opacity: 0 }}
      animate={{ y: 0, width: "-webkit-fill-available", opacity: 1 }}
      exit={{ y: -100, opacity: 0 }}
      transition={{ duration: 1 }}
    >
      {props.children}
    </motion.div>
  );
};

export default PageContainer;
