import Typewriter from "typewriter-effect";
import "./Typing.css";
function Typing() {
  return (
    <span className="normal">
      I am a
      <span className="typing">
        <Typewriter
          options={{
            strings: [
              "FullStack Developer",
              "Freelancer",
              "Graphic Designer",
              "Business Man",
              "Content Creator",
            ],
            autoStart: true,
            loop: true,
            deleteSpeed: 10,
          }}
        />
      </span>
    </span>
  );
}
export default Typing;
